export default function Sketch(p5) {
    let canvas;
    let x = 0;
    // let y = 0;

    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL);
        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        p5.push();
        p5.rotateX(p5.frameCount * 0.01);
        p5.rotateY(p5.frameCount * 0.01);
        p5.fill("white")
        p5.box(150);
        p5.translate(150, 150, 150);
        p5.box(50);
        p5.translate(-100, -200, -100);
        p5.box(25);
        p5.pop();
        p5.push();
        p5.rotateX(p5.frameCount * (-0.05));
        p5.rotateY(p5.frameCount * (-0.05));
        p5.translate(100, 100, 100);
        p5.fill('white');
        p5.box(25);
        p5.translate(50, -110, 110);
        p5.box(70);
        p5.translate(-100, -200, -100);
        p5.box(50);
        p5.pop();
        p5.push();
        p5.rotateX(p5.frameCount * (-0.025));
        p5.rotateY(p5.frameCount * 0.01);
        p5.translate(0, 100, 150);
        p5.fill('white');
        p5.box(40);
        p5.translate(-200, -200, -200);
        p5.box(20);
        p5.translate(100, 200, 100);
        p5.box(60);
        p5.pop();
        p5.push();
        p5.rotateX(p5.frameCount * (-0.01));
        p5.rotateY(p5.frameCount * 0.03);
        p5.translate(-100, 0, 120);
        p5.fill('white');
        p5.box(40);
        p5.translate(150, 150, 150);
        p5.box(70);
        p5.pop();

    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }


}






